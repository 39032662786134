<template>
    <div class="container">
        <el-form :model="form" :rules="rules" ref="appForm" label-width="140px" @submit.prevent="submit('appForm')" :close-on-click-modal="false">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="form.name" placeholder="输入软件名称，如Mysql"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="类型" prop="type_id">
                        <el-select v-model="form.type_id" placeholder="请选择">
                            <template v-for="item in typelist">
                                <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="最新版本" prop="version">
                        <el-input v-model="form.version" placeholder="输入应用版本号，如1.0.1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="管理者" prop="manager_id">
                        <el-select v-model="form.manager_id" placeholder="请选择管理者">
                            <template v-for="item in managerlist">
                                <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="默认端口" prop="port" >
                        <el-input v-model="form.port" placeholder="输入软件默认开放的端口号，如3306，443"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">

                </el-col>
            </el-row>
            <el-form-item label="备注" prop="note">
                <el-input v-model="form.note" type="textarea" :rows="2" placeholder="简述该软件的主要功能"></el-input>
            </el-form-item>

            <div style="margin-top:30px; text-align: center">
                <el-button type="primary" native-type="submit" @click.prevent="submit('appForm')">提交</el-button>
                <el-button @click="closePop">取消</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "SoftForm",
        props: {
            mode: {
                type: String
            },
            form: {
                type: Object
            }
        },
        data() {
            return {
                typelist: [],
                rules: {
                    name: [
                        { required: true, message: '名称不能为空', trigger: 'blur' },
                        { min: 2, max: 40, message: '长度2-40字符', trigger: 'blur' }
                    ],
                    type_id: [
                        { required: true, message: '请选择类型', trigger: 'change' }
                    ],
                    manager_id: [
                        { required: true, message: '请选择管理者', trigger: 'change' }
                    ],
                },
                managerlist: [],
            }
        },
        created() {
            this.getTypeList();
            this.getManagerList();
        },
        methods: {
            getTypeList() {
                this.$get(this.$api.GetAppTypelist).then((res) => {
                        this.typelist = res.data.list;
                });
            },
            //Manager
            getManagerList() {
                this.$get(this.$api.GetManagerList).then((res) => {
                        this.managerlist = res.data.list;
                });
            },

            closePop() {
                this.$emit('closePop');
            },
            submit (formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = {
                            id: this.form.id,
                            name: this.form.name,
                            type_id: this.form.type_id,
                            version: this.form.version,
                            port: this.form.port,
                            manager_id: this.form.manager_id,
                            note: this.form.note
                        };
                        let postUrl = this.$api.AddSoft;
                        if (this.mode == 'edit') {
                            postUrl = this.$api.EditSoft;
                        }
                        this.$post(postUrl, formData).then(() => {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success'
                                });
                                this.closePop();
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    /deep/.el-form-item{
        margin-bottom: 20px;
    }
</style>
