<template>
  <div class="container">
    <div class="page-title">
      <el-row>
        <el-col :span="18">
          <h3>软件管理</h3>
        </el-col>
        <el-col :span="6" style="text-align: right;">
          <el-button type="text" icon="el-icon-plus" @click="addApp">新增软件</el-button>
          <el-button type="text" icon="el-icon-document" @click="cateApp">软件类别管理</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="tab-wrap">
      <div class="query-box">
        <el-row class="row">
          <el-col :span="20">
            <el-form :inline="true" :model="queryParams" @submit.native.prevent>
              <el-form-item label="管理者" style="margin-bottom: 0">
                <el-select v-model="queryParams.manager_id" placeholder="请选择" style="width: 120px">
                  <el-option label="不限" value=""/>
                  <template v-for="item in managerlist">
                    <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="类型" style="margin-bottom: 0">
                <el-select v-model="queryParams.type_id" placeholder="请选择" style="width: 120px">
                  <el-option label="不限" value=""/>
                  <template v-for="item in typelist">
                    <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="关键词" style="margin-bottom: 0">
                <el-input placeholder="软件名称" v-model="queryParams.keys" style="width: 280px;">
                </el-input>
              </el-form-item>
              <el-form-item style="margin-bottom: 0">
                <el-button type="primary" native-type="submit" @click="searchData"><i class="el-icon-search"></i> 查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="4" style="text-align: right">

          </el-col>
        </el-row>
      </div>

      <el-table :data="tableData" ref="multipleTable" v-loading="loading" element-loading-text="加载中..." style="width: 100%" @selection-change="selChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="软件名称" prop="name">
        </el-table-column>
        <el-table-column label="类别" prop="type">
        </el-table-column>
        <el-table-column label="最新版本" prop="version">
        </el-table-column>
        <el-table-column label="默认服务端口" prop="port">
        </el-table-column>
        <el-table-column label="管理者" prop="manager">
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scope">
            <span class="row-opt"><el-link type="primary" icon="el-icon-document" :href="'/#/soft/detail?id='+scope.row.id">详情</el-link></span>
            <el-divider direction="vertical"></el-divider>
            <span class="row-opt" @click="editApp(scope.$index, scope.row)"><el-link type="primary" icon="el-icon-edit-outline">修改</el-link></span>
            <el-divider direction="vertical"></el-divider>
            <span class="row-opt" @click="deleteData(scope.row)"><el-link type="primary" icon="el-icon-delete">删除</el-link></span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagenation">
        <el-row>
          <el-col :span="8" style="padding-left:10px;">
            <el-button size="small" @click="toggleSelection()">取消选择</el-button>
            <el-button type="warning" icon="delete" size="small" @click="deleteData">删除所选</el-button>
          </el-col>
          <el-col :span="16" style="text-align:right">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 50]"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-dialog :title="popTitle" :visible.sync="addPop" :close-on-click-modal="false" width="60%">
      <hw-soft-form :mode="mode" :form="form" @closePop="closePop"></hw-soft-form>
    </el-dialog>

    <el-dialog title="导入软件" :visible.sync="showImportPop" :close-on-click-modal="false">
      <el-upload
              class="uploader"
              drag
              name="xlsx_file"
              :action="importExcel"
              :headers="headerObj"
              :show-file-list="false"
              :on-progress="uploading"
              :on-success="successUpload"
              :before-upload="beforeUpload"
              v-loading="waiting"
              element-loading-text="请稍等片刻>>>"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.7)"
              center>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将Excel文件拖到此处，或<em>点击导入</em></div>
        <div class="el-upload__tip" slot="tip">只能导入<span style="color: #f60">.xlsx</span>文件，且不超过2MB，请按照<a href="/muban/soft.xlsx">模板格式</a>编辑好再导入</div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
  import SoftForm from '@/components/pages/SoftForm.vue';

  export default {
    name: "App",
    components: {
      hwSoftForm: SoftForm,
    },
    data() {
      return {
        activeName: 'app',
        queryParams: {},
        loading: true,
        tableData: [],
        currentPage: 1,
        pageSize: 10,
        total: 0,
        multipleSelection: [],
        managerlist: [],
        typelist: [],
        addPop: false,
        popTitle: '新增软件',
        mode: 'add',
        form: {},
        showImportPop: false,
        importExcel: this.$api.BaseUrl + this.$api.ImportSoftExcel,
        headerObj: {
          'access-token': sessionStorage.getItem('access-token')
        },
        waiting: false,
      }
    },
    created() {
      this.getListData();
      this.getManagerList();
      this.getTypeList();
    },
    methods: {

      getListData (tag = '') {
        this.queryParams.page = this.currentPage;
        this.queryParams.limit = this.pageSize;
        this.queryParams.tag = tag;
        this.$get(this.$api.GetSoftList, this.queryParams).then((res) => {
          this.loading = false;
            this.tableData = res.data.list;
            this.total = res.data.total_count;
        });
      },
      getTypeList() {
        this.$get(this.$api.GetAppTypelist).then((res) => {
            this.typelist = res.data.list;
        });
      },
      getManagerList() {
        this.$get(this.$api.GetManagerList).then((res) => {
            this.managerlist = res.data.list;
        });
      },
      searchData() {
        this.loading = true;
        this.currentPage = 1;
        this.tableData = [];
        this.total = 0;
        this.getListData();
      },
      handleCurrentChange (val) {
        this.currentPage = val
        this.getListData()
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.getListData()
      },

      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      selChange(val) {
        this.multipleSelection = val;
      },
      //选择列表项
      selData(id) {
        let selId = '';
        if (id) { //单个
          selId = id;
        } else { //批量
          const length = this.multipleSelection.length;
          let idsArr = []
          for (let i = 0; i < length; i++) {
            idsArr.push(this.multipleSelection[i].id)
          }
          selId = idsArr.join();
        }
        return selId;
      },

      //删除列表选项
      deleteData(row) {
        let selId = this.selData(row.id);
        if (selId == '') {
          this.$message.error('请选择要删除的项');
          return false;
        }
        this.$confirm('此操作将删除所选项, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$post(this.$api.DeleteSoft, {id: selId})
              .then(() => {
                  this.$message({
                    message: '操作成功！',
                    type: 'success'
                  });
                  this.getListData();
              });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      addApp() {
        this.addPop = true;
        this.popTitle = '新增软件';
        this.mode = 'add';
        this.form = {};
      },
      editApp (index, row) {
        this.$get(this.$api.GetSoftInfo, {id: row.id}).then((res) => {
            this.addPop = true;
            this.popTitle = '编辑软件';
            this.mode = 'edit';
            this.form = res.data;
        });
      },
      closePop() {
        this.addPop = false;
        this.form = {};
        this.popTitle = '新增软件';
        this.mode = 'add';
        this.getListData('nocache');
      },
      beforeUpload(file) {
        console.log(file)
        if (file.size / 1024 / 1024 > 2) {
          this.$message.error('Excel文件大小不能超过 2MB!');
          return false;
        }

        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          return;
        } else {
          this.$message.error('只能上传.xlsx格式的Excel文件!');
          return false;
        }
      },
      successUpload(res, file) {
        console.log(file)
        this.waiting = false;
          this.$message.success('导入成功!');
          this.showImportPop = false;
          this.getListData();
      },
      uploading(event,file) {
        console.log(event, file)
        this.waiting = true;
      },

      //导出Excel
      exportData() {
        this.queryParams.page = 'all';
        this.queryParams.responseType = 'blob';
        this.$download(this.$api.ExportSoftExcel, this.queryParams).then((res) => {
          this.downloadExcel(res.data, '应用软件.xlsx');
        });
      },
      //下载保存Excel文件
      downloadExcel(data, filename) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        let a = document.createElement("a");
        let objUrl = URL.createObjectURL(blob);
        a.href = objUrl;
        a.download = filename //文件名
        a.click();
        URL.revokeObjectURL(objUrl); // 释放内存
      },
      //类别管理
      cateApp() {
        this.$router.push('soft/type');
      }
    }
  }
</script>

<style scoped lang="less">
  .tab-wrap{
    padding: 0 10px;
  }
  .query-box {
    background: #f6f6f6;
    padding: 10px;
    margin: 10px 0;
  }
  .pagenation{
    margin: 20px auto;
  }
  .uploader{
    width: 370px;
    margin: 0px auto;
    a{
      text-decoration: underline;
    }
  }
</style>
